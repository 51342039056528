// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 250px; /* Adjust width as needed */
    background-color: #f0f0f0; /* Background color */
    height: 100%;
    padding: 20px;
}

.sidebar-links {
    list-style: none;
    padding: 0;
}

.sidebar-links li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333;
}

.sidebar-links li a:hover {
    background-color: #ddd; /* Hover effect */
}

.link-style {
    background: none;
    border: none;
    color: grey; /* or your preferred link color */
    /* text-decoration: underline; */
    cursor: pointer;
    font-size: inherit;
    padding: 0;
    margin: 0;
}

.link-style:hover,
.link-style:focus {
    text-decoration: none;
}

/* Adjust styles as needed to match your app's design */
`, "",{"version":3,"sources":["webpack://./src/Sidebar/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,2BAA2B;IACzC,yBAAyB,EAAE,qBAAqB;IAChD,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,aAAa;IACb,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,sBAAsB,EAAE,iBAAiB;AAC7C;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW,EAAE,iCAAiC;IAC9C,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA,uDAAuD","sourcesContent":[".sidebar {\n    width: 250px; /* Adjust width as needed */\n    background-color: #f0f0f0; /* Background color */\n    height: 100%;\n    padding: 20px;\n}\n\n.sidebar-links {\n    list-style: none;\n    padding: 0;\n}\n\n.sidebar-links li a {\n    display: block;\n    padding: 10px;\n    text-decoration: none;\n    color: #333;\n}\n\n.sidebar-links li a:hover {\n    background-color: #ddd; /* Hover effect */\n}\n\n.link-style {\n    background: none;\n    border: none;\n    color: grey; /* or your preferred link color */\n    /* text-decoration: underline; */\n    cursor: pointer;\n    font-size: inherit;\n    padding: 0;\n    margin: 0;\n}\n\n.link-style:hover,\n.link-style:focus {\n    text-decoration: none;\n}\n\n/* Adjust styles as needed to match your app's design */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
