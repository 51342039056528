import React from 'react';
import Navbar from './Navbar';


const AdminDashboard = () => {
  
    return (
        <div className="container">
            <Navbar />
            <h1 className="mt-5">Admin Dashboard</h1>
            <p>Welcome to the Admin Dashboard. Here you can manage users, view reports, and perform administrative tasks.</p>


        </div>
    );
};

export default AdminDashboard;
