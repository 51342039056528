import React, { useState } from 'react';
import { signup } from './api/auth';

function SignUp() {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await signup(userData);
      console.log('Signup success:', data);
      // Handle successful signup (e.g., redirect or show success message)
    } catch (error) {
      console.error('Signup failed:', error);
      // Handle errors (e.g., show error message)
    }
  };
  return (
    <div>
      <h2>Sign Up</h2>
      <div className="row">
        <div className="col-md-4 offset-md-4">
            <form onSubmit={handleSubmit}>
                <div>
                <input
                    type="text"
                    className='form-control m-1'
                    placeholder='First Name'
                    id="firstName"
                    name="firstName"
                    value={userData.firstName}
                    onChange={handleChange}
                    required
                />
                </div>
                <div>
                <input
                    type="text"
                    className='form-control m-1'
                    placeholder='Last Name'
                    id="lastName"
                    name="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    required
                />
                </div>
                <div>
                <input
                    type="email"
                    className='form-control m-1'
                    placeholder='Email'
                    id="email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    required
                />
                </div>
                <div>
                <input
                    type="password"
                    className='form-control m-1'
                    placeholder='Password'
                    id="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                />
                </div>
                <button 
                    type="submit"
                    className='btn btn-secondary btn-sm m-1'
                >
                    Sign Up
                </button>
            </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
