import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import FacilityForm from './facility/FacilityForm';
import Sidebar from './Sidebar';
import { useSidebar } from './Sidebar/SidebarContext';
import FacilityList from './facility/FacilityList';
import MyCalendar from './calendar/MyCalendar'
import { getAppointments } from './api/facility';


const UserDashboard = () => {
    const [editMode, setEditMode] = useState(false);
    const [facilityToEdit, setFacilityToEdit] = useState(null);
    const [allAppointments, setAllAppointments] = useState([]);
    const { sidebarData } = useSidebar();

    const fetchAppointments = async () => {
        const fetchedAppointments = await getAppointments();
        if (!fetchedAppointments.error) {
            setAllAppointments(fetchedAppointments);
        }
    };
    
    useEffect(() => {

        fetchAppointments();
    }, []);

    const handleEditClick = (facility) => {
        setFacilityToEdit(facility);
        setEditMode(true);
    };

    const handleFacilitySaved = () => {
        setEditMode(false);
        setFacilityToEdit(null);
        // Refresh the list or other necessary actions
    };

    return (
        <div className="dashboard-container">
            <Navbar />
            <h1 className="mt-1">User Dashboard</h1>
            <Sidebar />
            <div className="container">
                <div className="row mt-1">
                    <div className="col-lg-3 col-md-12"></div>
                    <div className="col-lg-9 col-md-12">
                        {sidebarData.showCalendar && <MyCalendar appointments={allAppointments} />}
                        {sidebarData.showForm && <FacilityForm onFacilitySaved={handleFacilitySaved} />}
                        {
                            sidebarData.showFacilities && 
                            <FacilityList 
                                onEditClick={handleEditClick} 
                                editMode={ editMode } 
                                facilityToEdit={ facilityToEdit } 
                                setFacilityToEdit={ setFacilityToEdit } 
                                setEditMode={ setEditMode } 
                            />
                        }
                        {/* {editMode && <FacilityForm onFacilitySaved={handleFacilitySaved} facilityData={facilityToEdit} />} */}
                    </div>
                </div>
                

                {/* Add more user-specific components and functionality here */}
            </div>
        </div>
    );
};

export default UserDashboard;
