import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage';
import SignIn from './SignIn';
import SignUp from './SignUp';
import AdminRoute from './auth/AdminRoute';
import PrivateRoute from './auth/PrivateRoute';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import FacilityForm from './facility/FacilityForm';
import { SidebarProvider } from './Sidebar/SidebarContext';
import 'react-big-calendar/lib/css/react-big-calendar.css';


function App() {
  return (
    <SidebarProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/user/dashboard" element={<PrivateRoute component={UserDashboard} />} />
            <Route path="/user/add-facility" element={<PrivateRoute component={FacilityForm} />} />
            <Route path="/admin/dashboard" element={<AdminRoute component={AdminDashboard} />} />
            <Route path="/admin/add-facility" element={<AdminRoute component={FacilityForm} />} />
          </Routes>

        </div>
      </Router>
    </SidebarProvider>
  );
}

export default App;
