

const API = process.env.REACT_APP_API; // Your backend API URL
const log = console.log;

export const createFacility = async (facilityData) => {
    try {
        const response = await fetch(`${API}/facility/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(facilityData)
        });
        return await response.json();
    } catch (error) {
        log('Error creating facility:', error);
    }
};


export const getFacility = async (id) => {
    try {
        const response = await fetch(`${API}/facility/${id}`, {
            method: 'POST'
        });
        return await response.json();
    } catch (error) {
        console.error('Error fetching facility:', error);
    }
};

export const getAllFacilities = async () => {
    try {
        const response = await fetch(`${API}/facilities/getall`, {
            method: 'POST'
        });
        return await response.json();
    } catch (error) {
        console.error('Error fetching facility:', error);
    }
};

export const updateFacility = async (id, facilityData) => {
    try {
        const response = await fetch(`${API}/facility/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(facilityData)
        });
        return await response.json();
    } catch (error) {
        console.error('Error updating facility:', error);
    }
};


export const deleteFacility = async (id) => {
    try {
        const response = await fetch(`${API}/facility/${id}`, {
            method: 'DELETE'
        });
        return await response.json();
    } catch (error) {
        console.error('Error deleting facility:', error);
    }
};

export const getAppointments = async () => {
    try {
        const response = await fetch(`${API}/facilities/getappointments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Include any other headers like authentication tokens if required
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching appointments:', error);
        return { error: 'Failed to fetch appointments' };
    }
};
