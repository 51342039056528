import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
// import './Homepage.css';
import backgroundImage from './background.jpg'; // Adjust the path if necessary

function Homepage() {

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '100vh', // Full height of the viewport
  };

  return (
    <div style={backgroundStyle}>
      <Navbar />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12">
            <h1>Agape Client Manager</h1>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Homepage;
