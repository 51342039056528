// src/facility/FacilityForm.js
import React, { useState, useEffect } from 'react';
import { createFacility, updateFacility } from '../api/facility';
import { getAllFacilityTypes, createFacilityType } from '../api/facilityType';
import './styles.css'
const log = console.log;

const FacilityForm = ({ 
    onFacilitySaved, facilityData, updateFacilityInList, triggerUpdate
}) => {
    
    const initialState = {
        facilityName: '',
        facilityType: '',
        facilityAddress: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: ''
        },
        facilityPhoneNumber: '',
        contacts: [],
        notes: '',
        history: [],
        appointments: [],
    };
    
    const [showAddNewForm, setShowAddNewForm] = useState(false);
    const [facilityTypes, setFacilityTypes] = useState([]); 
    const [newFacilityType, setNewFacilityType] = useState('');

    // const [formData, setFormData] = useState(facilityData || initialState);
    const [currentFacilityData, setCurrentFacilityData] = useState(facilityData || initialState);
    const stateAbbreviations = ["State", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];
    
    const formHeading = facilityData ? "Update Facility Form" : "Add Facility Form";

    
    const fetchFacilityTypes = async () => {
        try {
            const fetchedTypes = await getAllFacilityTypes();
            setFacilityTypes(fetchedTypes.map( f => f.typeName));
        } catch (error) {
            console.error('Error fetching facility types:', error);
        }
    };
    
    useEffect(() => {
        fetchFacilityTypes();        
    }, [])
    
    const handleChange = (e) => {
        if ( e.target.value === `add-new`)
        {
            setShowAddNewForm(true)
        } else {
            setCurrentFacilityData({ ...currentFacilityData, [e.target.name]: e.target.value });
        }
    };

    const handleChangeForAddress = (e) => {
        const { name, value } = e.target;
        setCurrentFacilityData({ 
            ...currentFacilityData, 
            facilityAddress: { 
                ...currentFacilityData.facilityAddress, 
                [name]: value 
            } 
        });
    };

    
    // Function to handle adding a new appointment
    const addAppointment = () => {
        setCurrentFacilityData({
            ...currentFacilityData,
            appointments: [...currentFacilityData.appointments, { date: '', time: '', description: '' }]
        });
    };

    // Optionally, function to remove an appointment
    const removeAppointment = (index) => {
        const updatedAppointments = currentFacilityData.appointments.filter((_, i) => i !== index);
        setCurrentFacilityData({ ...currentFacilityData, appointments: updatedAppointments });
    };
    
    // Function to handle appointment field changes
    const handleAppointmentChange = (index, e) => {
        const updatedAppointments = currentFacilityData.appointments.map((appointment, i) => {
            if (index === i) {
                return { ...appointment, [e.target.name]: e.target.value };
            }
            return appointment;
        });
        setCurrentFacilityData({ ...currentFacilityData, appointments: updatedAppointments });
    };

    const handleCancel = () => {
        setShowAddNewForm(false)
    }

    const handleSubmitNewFacilityType = e => 
    {
        e.preventDefault();
        createFacilityType({typeName: newFacilityType})
        setShowAddNewForm(false);
        fetchFacilityTypes();  
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (facilityData) {
                // Call updateFacility API
                response = await updateFacility(facilityData._id, currentFacilityData);
                if (!response.error) {
                    updateFacilityInList(response); // Update the specific facility in FacilityList
                }
            } else {
                // Call createFacility API
                response = await createFacility(currentFacilityData);
                setCurrentFacilityData(initialState);
            }
    
            if (response.error) {
                console.error(response.error);
            } else {
                onFacilitySaved();
                // Reset form state if necessary
                triggerUpdate(); // Trigger the update
                
            }
        } catch (error) {
            console.error('Facility operation failed:', error);
        }
    };

    const handleContactChange = (index, e) => {
        const updatedContacts = currentFacilityData.contacts.map((contact, i) => {
            if (index === i) {
                return { ...contact, [e.target.name]: e.target.value };
            }
            return contact;
        });
        setCurrentFacilityData({ ...currentFacilityData, contacts: updatedContacts });
    };

    const addContact = () => {
        const newContact = {
            name: '',
            email: '',
            phoneNumber: currentFacilityData.facilityPhoneNumber, // Use facility's phone number as default
            phoneType: 'facility',
            extension: ''
        };
        setCurrentFacilityData({ ...currentFacilityData, contacts: [...currentFacilityData.contacts, newContact] });
    };


    const removeContact = (index) => {
        const updatedContacts = currentFacilityData.contacts.filter((_, i) => i !== index);
        setCurrentFacilityData({ ...currentFacilityData, contacts: updatedContacts });
    };

    const handleHistoryChange = (index, e) => {
        const updatedHistory = currentFacilityData.history.map((entry, i) => {
            if (index === i) {
                return { ...entry, [e.target.name]: e.target.value };
            }
            return entry;
        });
        setCurrentFacilityData({ ...currentFacilityData, history: updatedHistory });
    };
    
    const addHistoryEntry = () => {
        const today = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
        const newEntry = {
            t: today,
            note: '',
            method: ''
        };
        setCurrentFacilityData({ ...currentFacilityData, history: [...currentFacilityData.history, newEntry] });
    };
    
    
    const removeHistoryEntry = (index) => {
        const updatedHistory = currentFacilityData.history.filter((_, i) => i !== index);
        setCurrentFacilityData({ ...currentFacilityData, history: updatedHistory });
    };
    

    return (
        <form onSubmit={handleSubmit} className="facility-form">
            <div>
                <h3 className="text-center m-1">{formHeading}</h3>
            </div>
            
            <div className="form-group m-1 p-1" style={{ border: '0.1px solid grey'}}>
                <label htmlFor="facilityType">Facility Type</label>
                
                <input 
                    type="text" 
                    className='form-control m-1'
                    name="facilityType" 
                    onChange={handleChange} 
                    value={currentFacilityData.facilityType} 
                    placeholder="Facility Type" 
                    // disabled
                />
                <select 
                    id="facilityType" 
                    name="facilityType"
                    className="form-control"
                    value={currentFacilityData.facilityType}
                    onChange={handleChange}
                >
                    <option value="">Select Type</option>
                    {facilityTypes.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                    ))}
                    <option value="add-new">Add New Facility Type</option>
                </select>
            </div>
            {
                    showAddNewForm ?
                    <>
                        <div className='container p-1' style={{ border: '0.1px solid black'}}>
                            <input
                                type="text"
                                value={newFacilityType}
                                onChange={(e) => setNewFacilityType(e.target.value)}
                                placeholder="Enter new facility type"
                                className='form-control'
                            />
                            <button className='btn btn-secondary btn-sm m-1' onClick={handleSubmitNewFacilityType}>Save</button>
                            <button className='btn btn-danger btn-sm m-1' onClick={handleCancel}>Cancel</button>
                        </div>
                    </>
                    : null
                }
            <input 
                type="text" 
                className='form-control m-1'
                name="facilityName" 
                onChange={handleChange} 
                value={currentFacilityData.facilityName} 
                placeholder="Facility Name" 
            />
            
            <input 
                type="text" 
                name="address1" 
                className='form-control m-1'
                placeholder="Address Line 1" 
                value={currentFacilityData.facilityAddress.address1} 
                onChange={(e) => setCurrentFacilityData({ ...currentFacilityData, facilityAddress: { ...currentFacilityData.facilityAddress, address1: e.target.value }})} 
            />
            <input 
                type="text" 
                name="address2" 
                className='form-control m-1'
                placeholder="Suite or Apartment" 
                value={currentFacilityData.facilityAddress.address2} 
                onChange={(e) => setCurrentFacilityData({ ...currentFacilityData, facilityAddress: { ...currentFacilityData.facilityAddress, address2: e.target.value }})} 
            />
            <div className="address-row">
                <input 
                    type="text" 
                    className='form-control m-1 address-field city'
                    name="city" 
                    placeholder="City" 
                    value={currentFacilityData.facilityAddress.city}
                    onChange={handleChangeForAddress}
                />
                <select 
                    name="state" 
                    className='form-control m-1 address-field state'
                    value={currentFacilityData.facilityAddress.state}
                    onChange={handleChangeForAddress}>
                    {stateAbbreviations.map(state => (
                        <option key={state} value={state}>{state}</option>
                    ))}
                </select>
                <input 
                    type="text" 
                    className='form-control m-1 address-field zip'
                    name="zip" 
                    placeholder="Zip" 
                    value={currentFacilityData.facilityAddress.zip}
                    onChange={handleChangeForAddress}
                />
            </div>
        
            <input 
                type="text" 
                className='form-control m-1'
                name="facilityPhoneNumber" 
                onChange={handleChange} 
                value={currentFacilityData.facilityPhoneNumber} 
                placeholder="Phone Number" 
            />
            <div className="form-group my-2">
                <label className='m-2' htmlFor="notes">Notes</label>
                <textarea 
                    id="notes"
                    className="form-control"
                    name="notes" 
                    placeholder="Enter any notes here..." 
                    value={currentFacilityData.notes} 
                    onChange={handleChange}
                    rows="4" // You can adjust the number of rows as needed
                ></textarea>
            </div>

            
            <button className='btn btn-outline-secondary btn-sm m-1' type="button" onClick={addAppointment}>Add Appointment (+)</button>
            {currentFacilityData.appointments.map((appointment, index) => (
                <div key={index} className="appointment-section">
                    <input 
                        type="date" 
                        className='form-control m-1'
                        name="date" 
                        value={appointment.date} 
                        onChange={(e) => handleAppointmentChange(index, e)} 
                    />
                    <input 
                        type="time" 
                        className='form-control m-1'
                        name="time" 
                        value={appointment.time} 
                        onChange={(e) => handleAppointmentChange(index, e)} 
                    />
                    <input 
                        type="text" 
                        className='form-control m-1'
                        name="description" 
                        placeholder="Description" 
                        value={appointment.description} 
                        onChange={(e) => handleAppointmentChange(index, e)} 
                    />
                    <button 
                        type="button" 
                        className='btn btn-outline-danger btn-sm m-1' 
                        onClick={() => removeAppointment(index)}>
                        Remove Appointment (-)
                    </button>
                </div>
            ))}

            {/* Add fields for contacts, notes, etc. */}
            <button className='btn btn-outline-secondary btn-sm m-1' type="button" onClick={addContact}>Add Contact (+)</button>
            {currentFacilityData.contacts.map((contact, index) => (
                <div key={index} className="contact-section">
                    <input 
                        type="text" 
                        className='form-control m-1'
                        name="name" 
                        placeholder="Contact Name" 
                        value={contact.name} 
                        onChange={(e) => handleContactChange(index, e)} 
                    />
                    <input 
                        type="email" 
                        className='form-control m-1'
                        name="email" 
                        placeholder="Email" 
                        value={contact.email} 
                        onChange={(e) => handleContactChange(index, e)} 
                    />
                    <div className="d-flex align-items-center">
                        <select 
                            name="phoneType" 
                            style={{ flex: '1 0 33%' }} // 33% flex-basis and allow it to grow
                            className='form-control m-1'
                            value={contact.phoneType} 
                            onChange={(e) => handleContactChange(index, e)}>
                            <option value="facility">Office</option>
                            <option value="cell">Cell</option>
                            <option value="fax">Fax</option>
                        </select>
                        <input 
                            type="text" 
                            style={{ flex: '2 0 67%' }} // 67% flex-basis and allow it to grow
                            className='form-control m-1'
                            name="phoneNumber" 
                            placeholder="Phone Number" 
                            value={contact.phoneNumber} 
                            onChange={(e) => handleContactChange(index, e)} 
                        />
                    </div>
                    <button 
                        type="button" 
                        className='btn btn-outline-danger btn-sm m-1' 
                        onClick={() => removeContact(index)}>
                        Remove Contact (-)
                    </button>
                </div>
            ))}

            <button className='btn btn-outline-secondary btn-sm m-1' type="button" onClick={addHistoryEntry}>Add History Entry (+)</button>

            {currentFacilityData.history.map((entry, index) => (
                <div key={index} className="history-entry">
                    <input 
                        type="date" 
                        className='form-control m-1'
                        name="t" 
                        value={entry.t} 
                        onChange={(e) => handleHistoryChange(index, e)} 
                    />
                    <select 
                        name="method" 
                        className='form-control m-1'
                        value={entry.method} 
                        onChange={(e) => handleHistoryChange(index, e)}>
                        <option value="phone">Phone Call</option>
                        <option value="email">Email</option>
                        <option value="visit">Personal Visit</option>
                    </select>
                    <textarea 
                        rows="5" 
                        name="note" 
                        className='form-control m-1'
                        placeholder="Note" 
                        value={entry.note} 
                        onChange={(e) => handleHistoryChange(index, e)} 
                    />
                    <button 
                        type="button" 
                        className='btn btn-outline-danger btn-sm m-1' 
                        onClick={() => removeHistoryEntry(index)}>
                        Remove Entry (-)
                    </button>
                </div>
            ))}
            <div className="row justify-content-center my-5">
                <div className="col-md-4">
                    
                    <button 
                        type="submit"
                        className='btn btn-secondary btn-sm m-1'
                    >
                        Save Facility
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FacilityForm;
