// HistoryForm.js
import React, { useState } from 'react';

const HistoryForm = ({ onSave, onCancel, facility }) => {
    const [note, setNote] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(facility, note); // Pass the facility object and note
        setNote('');
    };
    

    return (
        <form onSubmit={handleSubmit}>
            <textarea
                value={note}
                className='form-control m-1'
                onChange={(e) => setNote(e.target.value)}
                placeholder="Enter history note"
            />
            <button className='btn btn-primary btn-sm m-1' type="submit">Save</button>
            <button className='btn btn-secondary btn-sm m-1' type="button" onClick={onCancel}>Cancel</button>
        </form>
    );
};

export default HistoryForm;
