import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAllFacilities } from '../api/facility';

const SidebarContext = createContext();

export function useSidebar() {
    return useContext(SidebarContext)
}

export function SidebarProvider({ children }) {
    const [sidebarData, setSidebarData] = useState({
        showCalendar: true, // Calendar is shown by default
        showForm: false,
        showFacilities: false,
        facilities: [] // Add this line to store facilities
    })
    
  const updateField = (field, value) => {
    setSidebarData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };


    return (
        <SidebarContext.Provider value={{ sidebarData, updateField }}>
            {children}
        </SidebarContext.Provider>
    )
}
