import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, signout } from './api/auth';

const Navbar = () => {
    const navigate = useNavigate();
    const { user } = isAuthenticated();

    const handleSignOut = () => {
        signout(() => {
            navigate('/');
        });
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
                <img src="/logo.png" alt="Logo" style={{ width: "100px" }} />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-end" id="navbarContent">
                <ul className="navbar-nav">
                    {user && user.role === 1 && (
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/dashboard">Admin Dashboard</Link>
                        </li>
                    )}
                    {user && (
                        <li className="nav-item">
                            <Link className="nav-link" to="/user/dashboard">User Dashboard</Link>
                        </li>
                    )}
                </ul>
                <ul className="navbar-nav">
                    {isAuthenticated() ? (
                        <li className="nav-item">
                            <button className="btn btn-outline-danger m-1" onClick={handleSignOut}>Sign Out</button>
                        </li>
                    ) : (
                        <>
                            <li className="nav-item">
                                <button className="btn btn-outline-secondary btn-sm m-1" onClick={() => handleNavigation('/signin')}>Sign In</button>
                            </li>
                            <li className="nav-item">
                                <button className="btn btn-outline-secondary btn-sm m-1" onClick={() => handleNavigation('/signup')}>Sign Up</button>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
