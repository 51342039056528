import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
const log = console.log;

const localizer = momentLocalizer(moment);

const MyCalendar = ({ appointments }) => {

    // Convert facility appointments to calendar events
    const appointmentEvents = appointments.map(appointment => {
      // Extract just the date part (YYYY-MM-DD) from the ISO string
      const datePart = appointment.date.split('T')[0];
  
      // Combine the extracted date part with the time
      const dateTimeString = `${datePart}T${appointment.time}:00`;
  
      const startTime = new Date(dateTimeString);
      const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add 1 hour to start time for end time
  
  
      return {
          title: `${appointment.facilityName}: ${appointment.description}`,
          start: startTime,
          end: endTime,
      };
  });
  
  
  

  // Merge appointment events with default events (if any)
  const allEvents = [
    ...appointmentEvents, // Add dynamic events
  ];


  return (
    <div>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default MyCalendar;
