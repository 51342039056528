import React, { useEffect, useState } from 'react';
import { getAllFacilities, updateFacility } from '../api/facility';
import { MdOutlineEdit } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import moment from 'moment';
import FacilityForm from './FacilityForm';
import PhoneScriptModal from './PhoneScriptModal';
import { getAllFacilityTypes } from '../api/facilityType';
import './styles.css'
import HistoryForm from './history/HistoryForm';

const FacilityList = ({ onEditClick, editMode, facilityToEdit, setFacilityToEdit, setEditMode }) => {
    const [facilities, setFacilities] = useState([]);
    const [activeRowId, setActiveRowId] = useState(null);
    const [editModes, setEditModes] = useState({}); // State to track edit modes
    const [updateTrigger, setUpdateTrigger] = useState(0); // Initialize with 0 or any default value
    const [newFacilityType, setNewFacilityType] = useState('');


    // State for modal visibility and contact name
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedContactName, setSelectedContactName] = useState('');
    const [selectedContactPhoneNumber, setSelectedContactPhoneNumber] = useState('');
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showAddNewForm, setShowAddNewForm] = useState(false);

    // State for history form
    const [showHistoryForm, setShowHistoryForm] = useState(false);
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);

    const fetchFacilities = async () => {
        try {
            const fetchedFacilities = await getAllFacilities();
            // Log to see the structure of fetched facilities
            // console.log('Fetched Facilities: ', fetchedFacilities);
            setFacilities(fetchedFacilities);
        } catch (error) {
            console.error('Error fetching facilities:', error);
        }
    };

    const updateFacilityInList = (updatedFacility) => {
        setFacilities(prevFacilities => {
            return prevFacilities.map(facility => {
                if (facility._id === updatedFacility._id) {
                    return updatedFacility;
                }
                return facility;
            });
        });
    };

    useEffect(() => {
    
        fetchFacilities();
        // console.log(`Ran FacilityList`)
    }, [updateTrigger]);

    // history form
    const handleAddHistoryClick = (facilityId) => {
        setShowHistoryForm(true);
        setSelectedFacilityId(facilityId);
    };

    const handleSaveHistory = async (facility, note) => {
        const updatedHistory = facility.history ? [...facility.history] : [];
        updatedHistory.push({ timestamp: new Date(), note });
    
        const updatedFacilityData = { ...facility, history: updatedHistory };
        const response = await updateFacility(facility._id, updatedFacilityData);
        if (response) {
            updateFacilityInList(response); // Update the specific facility in FacilityList
        }
        setShowHistoryForm(false);
        setSelectedFacilityId(null);
    };
    
    
    

    
    const openModalWithContact = (contactName, phoneNumber, facility) => {
        setSelectedContactName(contactName);
        setSelectedContactPhoneNumber(phoneNumber);
        setSelectedFacility(facility);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleEditToggle = (facilityId) => {
        const currentMode = editModes[facilityId] || false;
        setEditModes({ ...editModes, [facilityId]: !currentMode });
    };

    
    const handleRowClick = (facilityId) => {
        setActiveRowId(activeRowId === facilityId ? null : facilityId);
    };

    
    const handleFacilitySaved = () => {
        setEditMode(false);
        setFacilityToEdit(null);
        fetchFacilities(); // Re-fetch facilities to get updated data
    };

    

    const formatDate = (dateString) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-US', options);
    
        // Splitting the string and rearranging
        const [monthDay, yearAndTime] = formattedDate.split(", ");
        const [year, time] = yearAndTime.split(" at ");
    
        return `${monthDay}, ${year} @ ${time.replace(' AM', ' A.M.').replace(' PM', ' P.M.')}`;
    };

    const hasUpcomingAppointments = (appointments, facilityName) => {
        const today = moment().startOf('day');
        const inSevenDays = moment().add(7, 'days').endOf('day');
    
        return appointments.some(appointment => {
            // Format the date string and parse as UTC
            const appointmentDate = moment.utc(appointment.date).format("YYYY-MM-DD");
            const appointmentDateTimeString = `${appointmentDate}T${appointment.time}Z`; // Add 'Z' to indicate UTC
    
            // Convert to local time zone
            const appointmentDateTime = moment.utc(appointmentDateTimeString).local();
            const isUpcoming = appointmentDateTime.isSameOrAfter(today) && appointmentDateTime.isSameOrBefore(inSevenDays);
            // console.log(`Facility: ${facilityName}, Appointment DateTime: ${appointmentDateTime.format()}, Is Upcoming: ${isUpcoming}`);
            return isUpcoming;
        });
    };
    
    
    const displayAppointments = (appointments) => {
        const today = moment().startOf('day');
        const inNinetyDays = moment().add(90, 'days').endOf('day');
    
        return appointments.map((appointment, id) => {
            // Extract the hour from appointment time
            const hour = parseInt(appointment.time.split(':')[0], 10);
            const amPm = hour >= 12 && hour < 17 ? 'P.M.' : 'A.M.';
    
            // Format the time string
            const formattedTime = `${appointment.time} ${amPm}`;
    
            // Check if the appointment is within the next 7 days
            const appointmentDate = moment.utc(appointment.date);
            const appointmentDateTime = moment(`${appointmentDate.format("YYYY-MM-DD")}T${appointment.time}`);
            if (appointmentDateTime.isSameOrAfter(today) && appointmentDateTime.isSameOrBefore(inNinetyDays)) {
                return (
                    <div key={id} className="appointment-details">
                        <strong>{`${appointmentDate.format("MM-DD-YYYY")}`} {formattedTime} - {appointment.description}</strong>
                    </div>
                );
            }
            return null;
        }).filter(appointment => appointment !== null); // Filter out null values
    };
    

    const showFacilityData = (facility) => {
        const openScriptModal = () => {
            const contactName = facility.contacts && facility.contacts.length > 0 ? facility.contacts[0].name : '';
            const phoneNumber = facility.facilityPhoneNumber ? facility.facilityPhoneNumber : '';
            // console.log(`Opening modal with contact name: ${contactName}`);
            openModalWithContact(contactName, phoneNumber, facility);
        };
        
        

        return (
            <>
                {/* Facility Details Row */}
                <tr>
                    <td colSpan="100%">
                        <>
                            <div className="container text-left">
                                <div className="row">
                                    <div className="col-12">
                                        {/* Add Phone Script Modal Button */}
                                        <button className="btn btn-info btn-sm mb-3" onClick={openScriptModal}>
                                            Open Phone Script
                                        </button>

                                        {/* Facility Details ... */}
                                        {/* ... rest of your code ... */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">Facility name:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                <strong>
                                                    { facility.facilityName }
                                                </strong>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">Facility type:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                <strong>
                                                    { facility.facilityType }
                                                </strong>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">Facility address:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                <strong>
                                                    { facility.facilityAddress.address1 }&nbsp; { facility.facilityAddress.address2 }
                                                    { facility.facilityAddress.city },&nbsp;{ facility.facilityAddress.state }&nbsp;{ facility.facilityAddress.zip }
                                                </strong>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">phone:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                <strong>
                                                    
                                                    <a href={`tel:${facility.facilityPhoneNumber.replace(/[^0-9]/g, '')}`}>{ facility.facilityPhoneNumber }</a>
                                                </strong>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">contact(s):</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                <strong>
                                                    {
                                                        facility.contacts.map(( contact, id ) => {
                                                            return(
                                                                <div id={ id }>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            Name: { contact.name }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            email: <a href={`mailto:${contact.email}`}>{contact.email}</a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            direct phone: <a href={`tel:${contact.phoneNumber.replace(/[^0-9]/g, '')}`}>{contact.phoneNumber}</a>
                                                                            {contact.extension ? <span> Extension: {contact.extension}</span> : null} ({contact.phoneType})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </strong>
                                            </div>
                                        </div>
                                        {/* Appointments Section */}
                                        <hr />
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">Upcoming Appointments:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                {
                                                    facility.appointments && facility.appointments.length > 0 ? (
                                                        displayAppointments(facility.appointments)
                                                    ) : (
                                                        <p>No upcoming appointments.</p>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <hr />
                                        <div className='row my-2'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">notes:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                <strong>
                                                    { facility.notes }
                                                </strong>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">history:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                {
                                                    facility.history
                                                    .map(( activity, id ) => {
                                                        return(
                                                            <div id={ id }>
                                                                <div>{formatDate(activity.timestamp)}</div>
                                                                <div>{ activity.note }</div>
                                                                <hr />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {/* Show Add History Button */}
                                                <button className='btn btn-primary btn-sm' onClick={() => handleAddHistoryClick(facility._id)}>
                                                    Add History Event
                                                </button>

                                                {/* Show History Form */}
                                                {showHistoryForm && selectedFacilityId === facility._id && (
                                                    <HistoryForm
                                                        onSave={handleSaveHistory}
                                                        onCancel={() => setShowHistoryForm(false)}
                                                        facility={facility}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1">
                                        <button title='edit' className='btn btn-light btn-sm m-1' onClick={() => handleEditToggle(facility._id)}>
                                            <MdOutlineEdit />
                                        </button>
                                    </div>
                                    {
                                        editMode && 
                                        <FacilityForm 
                                            onFacilitySaved={handleFacilitySaved} 
                                            facilityData={facilityToEdit} 
                                            updateFacilityInList={updateFacilityInList} 
                                            triggerUpdate={() => setUpdateTrigger(prev => prev + 1)}
                                            setShowAddNewForm={setShowAddNewForm}
                                            showAddNewForm={showAddNewForm}
                                            newFacilityType={newFacilityType}
                                            setNewFacilityType={setNewFacilityType}
                                        />
                                    }
                                </div>
                            </div>
                        </>
                    </td>
                </tr>
                {/* Edit Form Row */}
                {editModes[facility._id] && (
                    <tr>
                        <td colSpan="100%">
                            <FacilityForm 
                                onFacilitySaved={() => setEditModes({...editModes, [facility._id]: false})} 
                                facilityData={facility} 
                                updateFacilityInList={updateFacilityInList} 
                                triggerUpdate={() => setUpdateTrigger(prev => prev + 1)}
                                setShowAddNewForm={setShowAddNewForm}
                                showAddNewForm={showAddNewForm}
                                newFacilityType={newFacilityType}
                                setNewFacilityType={setNewFacilityType}
                            />
                        </td>
                    </tr>
                )}
            </>
        );
    };
    
    
    return (
        <div>
            <h2>Facilities</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Phone</th>
                        <th>Upcoming</th> 
                    </tr>
                </thead>
                <tbody>
                    {facilities.map(facility => {
                        // console.log(`Facility: ${facility.facilityName}, Appointments: `, facility.appointments); // Log each facility's appointments
                        return (
                            <>
                                <tr key={facility._id} onClick={() => handleRowClick(facility._id)}>
                                        <td></td>
                                        <td className="text-left">{facility.facilityName}</td>
                                        <td className="text-left">{ facility.facilityType }</td>
                                        <td className="text-left">{facility.facilityPhoneNumber}</td>
                                        <td>
                                            {hasUpcomingAppointments(facility.appointments, facility.facilityName) ? <FcCalendar /> : null}
                                        </td>
                                </tr>
                                {activeRowId === facility._id && showFacilityData(facility)}
                                
                            </>
                        )
                    })}
                </tbody>
            </table>
            
            {/* Modal for Phone Script */}
            <PhoneScriptModal
                show={isModalOpen}
                onClose={closeModal}
                contactName={selectedContactName}
                phoneNumber={selectedContactPhoneNumber}
                facility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                onFacilitySaved={() => setEditModes({...editModes, [selectedFacility._id]: false})} 
                updateFacilityInList={updateFacilityInList}
                fetchFacilities={fetchFacilities}
                triggerUpdate={() => setUpdateTrigger(prev => prev + 1)}
                displayAppointments={displayAppointments}
            />
        </div>
    );
};

export default FacilityList;
