// src/api/facilityType.js

const API = process.env.REACT_APP_API; // Your backend API URL

export const createFacilityType = async (facilityTypeData) => {
    const response = await fetch(`${API}/facilityType`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(facilityTypeData),
    });
    return response.json();
};

export const getAllFacilityTypes = async () => {
    const response = await fetch(`${API}/facilityTypes`, {
        method: 'POST',
    });
    return response.json();
};

export const getFacilityTypeById = async (id) => {
    const response = await fetch(`${API}/facilityType/${id}`, {
        method: 'POST',
    });
    return response.json();
};

export const updateFacilityType = async (id, facilityTypeData) => {
    const response = await fetch(`${API}/facilityType/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(facilityTypeData),
    });
    return response.json();
};

export const deleteFacilityType = async (id) => {
    const response = await fetch(`${API}/facilityType/${id}`, {
        method: 'DELETE',
    });
    return response.json();
};
