import React from 'react';
import { useSidebar } from './SidebarContext';
import './styles.css'

const Sidebar = () => {
    const { updateField, sidebarData } = useSidebar();

    const handleCalendarClick = () => {
        updateField('showCalendar', true);
        updateField('showForm', false);
        updateField('showFacilities', false);
    };

    const handleAddFacilityClick = () => {
        updateField('showCalendar', false);
        updateField('showForm', true);
        updateField('showFacilities', false);
    };

    const handleFacilitiesClick = () => {
        updateField('showCalendar', false);
        updateField('showForm', false);
        updateField('showFacilities', true);
    };

    return (
        <div className="sidebar">
            <ul className="sidebar-links">
                <li>
                    <button className="link-style" onClick={handleCalendarClick}>Calendar</button>
                </li>
                <li>
                    <button className="link-style" onClick={handleAddFacilityClick}>Add Facility</button>
                </li>
                <li>
                    <button className="link-style" onClick={handleFacilitiesClick}>Facilities</button>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
