import React from 'react';
import './styles.css'
import { createFacility, updateFacility } from '../api/facility';

const PhoneScriptModal = ({ 
    show, onClose, contactName, phoneNumber, facility, setSelectedFacility, 
    onFacilitySaved, updateFacilityInList, fetchFacilities, triggerUpdate,
    displayAppointments
}) => {
    const speakStyle = {
        color: 'blue'
    }
    
    // Function to get the current time greeting
    const getCurrentTimeGreeting = () => {
        const currentHour = new Date().getHours();
        return currentHour < 12 ? "morning" : "afternoon";
    };

    const currentTimeGreeting = getCurrentTimeGreeting();

    if (!show) return null;

    const initialState = {
        facilityName: '',
        facilityAddress: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: ''
        },
        facilityPhoneNumber: '',
        contacts: [],
        notes: '',
        history: [],
        appointments: [],
    };

    const handleContactChange = (index, e) => {
        const updatedContacts = facility.contacts.map((contact, i) => {
            if (index === i) {
                return { ...contact, [e.target.name]: e.target.value };
            }
            return contact;
        });
        setSelectedFacility({ ...facility, contacts: updatedContacts });
    };

    const addContact = () => {
        const newContact = {
            name: '',
            email: '',
            phoneNumber: facility.facilityPhoneNumber, // Use facility's phone number as default
            phoneType: 'facility',
            extension: ''
        };
        setSelectedFacility({ ...facility, contacts: [...facility.contacts, newContact] });
    };


    const removeContact = (index) => {
        const updatedContacts = facility.contacts.filter((_, i) => i !== index);
        setSelectedFacility({ ...facility, contacts: updatedContacts });
    };

    const closeAddContact = () => {
        setSelectedFacility({ ...facility, contacts: [] })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (facility) {
                // Call updateFacility API
                response = await updateFacility(facility._id, facility);
                if (!response.error) {
                    updateFacilityInList(response); // Update the specific facility in FacilityList
                }
            } else {
                // Call createFacility API
                response = await createFacility(facility);
                setSelectedFacility(initialState);
            }
    
            if (response.error) {
                console.error(response.error);
            } else {
                onFacilitySaved();
                // Reset form state if necessary
                closeAddContact()
                triggerUpdate(); // Trigger the update
            }
        } catch (error) {
            console.error('Facility operation failed:', error);
        }
    };

    const showContact = () => {
        return(
            <>
                {
                    facility && facility.contacts ?
                    facility.contacts.map( (contact, i ) => {
                        return(
                            <div className='text-center' style={{ fontSize: '25px', fontWeight: 'bold' }} key={ i }>
                                {contact && contact.name ? <p>name: {contact.name}</p> : null}
                                {contact && contact.email ? <p>email: {contact.email}</p> : null}
                            </div>
                        )
                    }
                    )
                    : null
                }
            </>
        )
    }

    return (
        <div className="modal" style={{ display: show ? 'block' : 'none' }}>
            <div className="modal-content">
                <span className="close-button" onClick={onClose}>&times;</span>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="text-center m-1">
                                Phone Script for Setting an Appointment
                            </h3>
                            <hr />
                            <div className="container">
                                <div className="row">
                                    <div className="col-mb-4"style={{ fontSize: '25px', fontWeight: 'bold' }}>
                                        <p className="text-center">
                                            {facility.facilityName} <br />
                                            Phone: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> <br />
                                            {
                                                contactName ? 
                                                <p>Contact name: {contactName}</p> 
                                                : facility && facility.contacts && facility.contacts.length > 0 ?
                                                showContact()
                                                : null
                                            }
                                        </p>
                                    </div>
                                </div>
                                
                                <div className='row'>
                                            <div className="col-lg-3 col-md-12">
                                                <p className="text-muted">Upcoming Appointments:</p>
                                            </div>
                                            <div className="col-lg-9 col-md-12">
                                                {
                                                    facility.appointments && facility.appointments.length > 0 ? (
                                                        displayAppointments(facility.appointments)
                                                    ) : (
                                                        <p>No upcoming appointments.</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                <div className="row">
                                    <div className="col-mb-4">
                                        <form onSubmit={handleSubmit} className="facility-form">
                                            {/* Add fields for contacts, notes, etc. */}
                                            {/* <button className='btn btn-outline-secondary btn-sm m-1' type="button" onClick={addContact}>Add Contact (+)</button> */}
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button className='btn btn-outline-secondary btn-sm m-1' type="button" onClick={addContact}>
                                                        Add Contact (+)
                                                    </button>
                                                </div>
                                            </div>
                                            {facility.contacts.map((contact, index) => (
                                                <div key={index} className="contact-section">
                                                    <div>
                                                        <input 
                                                            type="text" 
                                                            className='form-control m-1'
                                                            name="name" 
                                                            placeholder="Contact Name" 
                                                            value={contact.name} 
                                                            onChange={(e) => handleContactChange(index, e)} 
                                                        />
                                                        <input 
                                                            type="email" 
                                                            className='form-control m-1'
                                                            name="email" 
                                                            placeholder="Email" 
                                                            value={contact.email} 
                                                            onChange={(e) => handleContactChange(index, e)} 
                                                        />
                                                        <div className="d-flex align-items-center">
                                                            <select 
                                                                name="phoneType" 
                                                                style={{ flex: '1 0 33%' }} // 33% flex-basis and allow it to grow
                                                                className='form-control m-1'
                                                                value={contact.phoneType} 
                                                                onChange={(e) => handleContactChange(index, e)}>
                                                                <option value="facility">Office</option>
                                                                <option value="cell">Cell</option>
                                                                <option value="fax">Fax</option>
                                                            </select>
                                                            <input 
                                                                type="text" 
                                                                style={{ flex: '2 0 67%' }} // 67% flex-basis and allow it to grow
                                                                className='form-control m-1'
                                                                name="phoneNumber" 
                                                                placeholder="Phone Number" 
                                                                value={contact.phoneNumber} 
                                                                onChange={(e) => handleContactChange(index, e)} 
                                                            />
                                                        </div>
                                                        <button 
                                                            type="button" 
                                                            className='btn btn-outline-danger btn-sm m-1' 
                                                            onClick={() => removeContact(index)}>
                                                            Remove Contact (-)
                                                        </button>
                                                        <button 
                                                            type="button" 
                                                            className='btn btn-outline-secondary btn-sm m-1' 
                                                            onClick={() => closeAddContact()}>
                                                            Close Contact (-)
                                                        </button>
                                                    </div>
                                                    <div className="row justify-content-center my-5">
                                                        <div className="col-md-4">
                                                            
                                                            <button 
                                                                type="submit"
                                                                className='btn btn-secondary btn-sm m-1'
                                                            >
                                                                Save Facility
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5>1. Introduction:</h5>
                                <p className='text-muted'>Greeting:</p>
                                <p style={speakStyle}>"Good {currentTimeGreeting}, this is Eric Valencia from Agape First Family Caregiving Agency. May I speak with {contactName || 'the [discharge planner/case manager]'}?"</p>
                                
                                <p style={{ fontWeight: 'bold' }}>Connection:</p>
                                <p className='text-muted'>If speaking to the right person:</p>
                                <p style={speakStyle}>"Thank you for taking my call, {contactName || '[Their Name]'}. I appreciate your time."</p>
                            </div>
                            <hr />
                            <div>
                                <h5>2. Purpose of the Call:</h5>
                                <p className='text-muted'>Brief Introduction of Agape:</p>
                                <p style={speakStyle}>"I'm reaching out to discuss how Agape can partner with your facility to provide a home care nurse for your patients after discharge."</p>
                            </div>
                            <div>
                                <details>
                                    <summary>Services Offered</summary>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>&bull;&nbsp;Companionship</li>
                                        <li>&bull;&nbsp;Personal Care</li>
                                        <li>&bull;&nbsp;Doctor Visits</li>
                                        <li>&bull;&nbsp;Transportation (Access)</li>
                                        <li>&bull;&nbsp;Medication Reminders</li>
                                        <li>&bull;&nbsp;Standby or Walking Assistance</li>
                                        <li>&bull;&nbsp;Activities/Light Exercise</li>
                                    </ul>
                                </details>
                            </div>
                            <div>
                                <details>
                                    <summary>Household Assistance</summary>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>&bull;&nbsp;Light Housekeeping</li>
                                        <li>&bull;&nbsp;Meal Preparation</li>
                                        <li>&bull;&nbsp;Errands/Shopping</li>
                                        <li>&bull;&nbsp;Laundry</li>
                                    </ul>
                                </details>
                            </div>
                            <div>
                                <details>
                                    <summary>Caregiver Requirements</summary>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>&bull;&nbsp;Experienced</li>
                                        <li>&bull;&nbsp;Verified References</li>
                                        <li>&bull;&nbsp;National Background Check</li>
                                        <li>&bull;&nbsp;Fingerprinted</li>
                                        <li>&bull;&nbsp;Ongoing Training</li>
                                    </ul>
                                </details>
                            </div>
                            <div>
                                <details>
                                    <summary>Care Solutions</summary>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>&bull;&nbsp;Client Consultation</li>
                                        <li>&bull;&nbsp;Individualized Plan</li>
                                        <li>&bull;&nbsp;Home Safety Survey</li>
                                        <li>&bull;&nbsp;Caregiver Skill/Personality Match-up</li>
                                        <li>&bull;&nbsp;Introduction to Caregiver</li>
                                    </ul>
                                </details>
                            </div>
                            <div>
                                <details>
                                    <summary>Personalized Involvement</summary>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>&bull;&nbsp;Monthly Supervised Visits</li>
                                        <li>&bull;&nbsp;Care Coordination of Client</li>
                                        <li>&bull;&nbsp;Monthly Calendar Updates of Client</li>
                                        <li>&bull;&nbsp;Vendor Recommendations</li>
                                    </ul>
                                </details>
                            </div>
                            <hr />
                            <div>
                                <h5>3. Setting the Appointment:</h5>
                                <p className='text-muted'>Request for Appointment:</p>
                                <p style={speakStyle}>"I’d love the opportunity to share more about our services and how we can work together for the betterment of your patients. Would it be possible to schedule a brief, 10-minute meeting at your convenience?"</p>
                                
                                <p className='text-muted'>Highlighting the Benefit:</p>
                                <p style={speakStyle}>"In our meeting, I’ll provide a quick overview of our tailored care solutions, ranging from personal care to household assistance, and how these can significantly benefit your patients transitioning back home."</p>
                            </div>
                            <hr />
                            <div>
                                <h5>4. Handling Responses:</h5>
                                <p className='text-muted'>If They Agree:</p>
                                <p style={speakStyle}>"Thank you so much, {contactName || '[Their Name]'}. What day and time would be most convenient for you?"</p>
                                <br />
                                <p className='text-muted'>If They Hesitate or Decline:</p>
                                <p style={speakStyle}>"I understand your time is valuable. May I send you some information via email and perhaps we can touch base at a later date? We truly believe in our ability to enhance the post-discharge experience for your patients, and I think it could be beneficial for us to connect."</p>
                            </div>
                            <hr />
                            <div>
                                <h5>5. Closing the Call:</h5>
                                <p className='text-muted'>Thank Them & Confirm Details:</p>
                                <p style={speakStyle}>"Thank you, {contactName || '[Their Name]'}. I look forward to our meeting on [confirmed date/time]. I’ll also send a calendar invite for your convenience."</p>
                                <br />
                                <p className='text-muted'>Closing Remarks:</p>
                                <p style={speakStyle}>"Have a wonderful day, and thank you again for considering Agape First Family Caregiving Agency. We are committed to providing compassionate and quality care, treating every client like family."</p>
                            </div>
                            <hr />
                            <div>
                                <h5>6. End of Call</h5>
                                <p style={speakStyle}>"Goodbye, and take care."</p>
                            </div>
                            <hr />
                            <div>
                                <h5>Notes</h5>
                                <p>
                                    &bull;&nbsp;Remember, the key is to be respectful, concise, and clear about the value Agape can bring to their patients. Adjust the script as needed based on the response and the flow of the conversation. Good luck!
                                </p>
                            </div>
                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhoneScriptModal;
